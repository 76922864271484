import React from "react";
import Box from "@mui/material/Box";
import { SxProps } from "@mui/system/styleFunctionSx";
import { motion } from "framer-motion";

let MBox = motion(Box);

type PropsBase = {
  children: React.ReactNode;
  sx: SxProps;
};

export function Base(props: PropsBase) {
  return (
    <MBox
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflowY: "auto",
        ...props.sx,
      }}
      initial={{ x: 20, opacity: 0.5 }}
      animate={{ x: 0, opacity: 1 }}
    >
      {props.children}
    </MBox>
  );
}

Base.defaultProps = {
  sx: {},
};
