import { HashRouter as Router } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import CssBaseline from "@mui/material/CssBaseline";
import { ToastContainer } from 'react-toastify';

import { queryClient } from "./config/react-query";
import { ProviderTheme } from "./config/mui";
import { ProviderAuth } from "./context/Auth";
import { RoutesApp } from "./routes";

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ProviderTheme>
        <CssBaseline enableColorScheme />
        <Router>
          <ProviderAuth>
            <RoutesApp />
            <ToastContainer />
          </ProviderAuth>
        </Router>
      </ProviderTheme>
    </QueryClientProvider>
  );
}
