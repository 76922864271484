import React from "react";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Gravatar from "react-gravatar";

import { useAuth } from "../context/Auth";
import { useAppStore, CurrentOrg } from "../App.store";

type PropsFSidebar = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export function FSidebar(props: PropsFSidebar) {
  let { user, logout } = useAuth();
  let { currentOrg, setCurrentOrg } = useAppStore();

  let orgsAsOptions = React.useMemo(
    () =>
      user?.empresas.map((i) => ({
        id: i.id,
        cnpjCpf: i.pessoa.cnpjCpf,
        nomeFantasia: i.pessoa.nomeFantasia,
        tipoModulo: i.tipoModulo,
      })) ?? [],
    [user?.empresas]
  );

  return (
    <Drawer anchor="left" open={props?.isOpen} onClose={() => props?.setIsOpen?.(false)}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 300,
          height: "100%",
          p: 2,
        }}
      >
        <Box
          sx={{ mx: "auto", my: 2, display: "flex", flexDirection: "column", alignItems: "center" }}
        >
          <Gravatar size={65} email={user?.email} style={{ borderRadius: "50%" }} />
          <Typography sx={{ mt: 1 }} variant="subtitle1">
            {user?.pessoa.nome}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" sx={{ mb: 2 }}>
            Configurações Fluid
          </Typography>
          <Autocomplete
            options={orgsAsOptions}
            getOptionLabel={(option: any) => option?.nomeFantasia}
            value={currentOrg}
            onChange={(_, value: CurrentOrg | null) => {
              if (value) {
                setCurrentOrg(value);
              }
            }}
            renderInput={(params) => <TextField {...params} label="Empresa atual" />}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 0.5,
            mt: "auto",
          }}
        >
          <Button disableElevation variant="contained" onClick={() => logout()}>
            SAIR
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
