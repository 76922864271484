import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";

import * as MI from "./MuiIcon";

export function FButtonBack() {
  let navigate = useNavigate();

  return (
    <IconButton onClick={() => navigate(-1)}>
      <MI.ArrowBack />
    </IconButton>
  );
}
