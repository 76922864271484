import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import React from "react";
import { SxProps, useTheme } from "@mui/material/styles";
import moment from "moment";
import { Global, css } from "@emotion/react";
import {
  DateRangePicker,
  FocusedInputShape as FocusedInput,
} from "react-dates";

type Props = {
  sx?: SxProps;
  startDate: any;
  endDate: any;
  onDatesChange: any;
};

export function RDDateRangePicker(props: Props) {
  let { palette } = useTheme();
  let [focusedInput, setFocusedInput] = React.useState<FocusedInput | null>(
    null
  );

  return (
    <div style={props.sx as any}>
      <Global
        styles={css`
          .DateRangePicker_picker {
            z-index: 100;
          }

          .CalendarDay__selected_span {
            background: ${palette.primary.light};
            color: ${palette.primary.contrastText};
            border: 1px solid ${palette.primary.light};
          }

          .CalendarDay__selected {
            background: ${palette.primary.dark};
            color: ${palette.primary.contrastText};
            border: 1px solid ${palette.primary.dark};
          }

          .CalendarDay__selected:hover {
            background: ${palette.primary.main};
            color: ${palette.primary.contrastText};
          }

          .CalendarDay__hovered_span:hover,
          .CalendarDay__hovered_span {
            background: ${palette.primary.main};
          }
        `}
      />
      <DateRangePicker
        displayFormat={"DD/MM/YYYY"}
        startDate={props.startDate}
        startDateId="startDateId"
        endDate={props.endDate}
        endDateId="endDateId"
        onDatesChange={props.onDatesChange}
        focusedInput={focusedInput}
        onFocusChange={(fi) => setFocusedInput(fi)}
        orientation="vertical"
        hideKeyboardShortcutsPanel
        withFullScreenPortal
        minimumNights={0}
        isOutsideRange={(date) => {
          return date.isAfter(moment());
        }}
        initialVisibleMonth={() => {
          if (moment(props.endDate).month() === moment().month()) {
            return moment(props.endDate).subtract(1, "month");
          }

          return moment(props.endDate);
        }}
        noBorder
      />
    </div>
  );
}
