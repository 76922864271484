import create from "zustand";
import { persist } from "zustand/middleware";
import ls from "./config/local-storage";

export type CurrentOrg = {
  id: string;
  cnpjCpf: string;
  nomeFantasia: string;
  tipoModulo: number;
};

export type AppStore = {
  logo: string;
  color: string;
  mode: "light" | "dark";
  currentOrg: CurrentOrg | null;
  setCurrentOrg: (org: CurrentOrg | null) => void;
  toggleDarkTheme: () => void;
};

export let useAppStore = create(
  persist<AppStore>(
    (set, get) => ({
      color: "#000",
      mode: "light",
      logo: "/sls-fluid-app/img/svg/gota.svg",
      currentOrg: null,
      setCurrentOrg: (org: CurrentOrg | null) => {
        let { color, logo } = getOrgTheme(org?.tipoModulo);

        return set({ currentOrg: org, color, logo });
      },
      toggleDarkTheme: () => set({ mode: get().mode === "light" ? "dark" : "light" }),
    }),
    { name: ls.PREFIX + "store" }
  )
);

export function getOrgTheme(tipoModulo?: number) {
  if (tipoModulo === 1) {
    return { color: "#064970", logo: "/sls-fluid-app/img/svg/sgap.svg" };
  }

  if (tipoModulo === 2) {
    return { color: "#020272", logo: "/sls-fluid-app/img/svg/allpdv.svg" };
  }

  return { color: "#000", logo: "/sls-fluid-app/img/svg/gota.svg" };
}
