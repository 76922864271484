import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import OutlinedInput, { OutlinedInputProps } from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import IconVisibilityOff from "@mui/icons-material/VisibilityOff";
import IconVisibility from "@mui/icons-material/Visibility";

type PropsMuiTextFieldSecure = OutlinedInputProps & {
  label: string;
  helperText?: string | false;
};

export function MuiTextFieldSecure(props: PropsMuiTextFieldSecure) {
  let [isVisible, setIsVisible] = useState(false);

  let { label, sx, helperText, error, fullWidth, ...inputProps } = props;

  return (
    <FormControl variant="outlined" sx={sx} fullWidth={fullWidth}>
      <InputLabel
        htmlFor="adornment-password"
        sx={{
          backgroundColor: "background.paper",
          padding: "0 5px",
          marginLeft: "-5px",
        }}
        error={error}
      >
        {label}
      </InputLabel>
      <OutlinedInput
        id="adornment-password"
        type={isVisible ? "text" : "password"}
        error={error}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={() => setIsVisible(!isVisible)} edge="end">
              {isVisible ? <IconVisibilityOff /> : <IconVisibility />}
            </IconButton>
          </InputAdornment>
        }
        {...inputProps}
      />
      {helperText ? (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      ) : null}
    </FormControl>
  );
}
