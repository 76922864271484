import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { useAppStore } from "../App.store";

type PropsProviderTheme = {
  children: React.ReactNode;
};

export function ProviderTheme(props: PropsProviderTheme) {
  let { color, mode } = useAppStore();

  let theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
          primary: { main: color },
        },
      }),
    [color, mode]
  );

  React.useEffect(() => {
    let metaThemeColor = document.querySelector("meta[name=theme-color]");

    if (metaThemeColor) {
      metaThemeColor.setAttribute("content", mode === "dark" ? "#121212" : "white");
    }
  }, [mode]);

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}
