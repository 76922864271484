import React from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { SxProps } from "@mui/system/styleFunctionSx";

type PropsMuiDataTable = {
  data: any[];
  columns: any[];
  columnFixedLeft: string[];
  sx?: SxProps;
};

export function MuiDataTable(props: PropsMuiDataTable) {
  let [page, setPage] = React.useState(0);
  let [rowsPerPage, setRowsPerPage] = React.useState(5);

  let table = useReactTable({
    state: { columnPinning: { left: props.columnFixedLeft } },
    data: props.data,
    columns: props.columns,
    getCoreRowModel: getCoreRowModel(),
  });

  React.useEffect(() => {
    setPage(0);
  }, [props.data]);

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          boxShadow: "none",
          borderRadius: 0,
          border: 1,
          borderColor: "divider",
          borderBottom: "none",

          "& td, & th": {
            whiteSpace: "nowrap",
          },
          ...props.sx,
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Table
            sx={{
              textAlign: "center",
              borderCollapse: "separate",
              borderSpacing: 0,
            }}
          >
            <TableHead>
              {table.getHeaderGroups().map((hg) => {
                return (
                  <TableRow key={hg.id}>
                    {hg.headers.map((h) => {
                      let isFixedLeft = props.columnFixedLeft.includes(
                        h.column.id
                      );

                      return (
                        <TableCell
                          key={h.id}
                          sx={
                            isFixedLeft
                              ? {
                                  position: "sticky",
                                  left: 0,
                                  borderRight: "1px solid divider",
                                  backgroundColor: "background.paper",
                                  boxShadow:
                                    "2px 0 10px -3px rgba(0, 0, 0, 0.1)",
                                }
                              : {}
                          }
                        >
                          {h.isPlaceholder
                            ? null
                            : flexRender(
                                h.column.columnDef.header,
                                h.getContext()
                              )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableHead>
            <TableBody>
              {table
                .getRowModel()
                .rows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                .map((r) => {
                  return (
                    <TableRow key={r.id}>
                      {r.getVisibleCells().map((c) => {
                        let isFixedLeft = props.columnFixedLeft.includes(
                          c.column.id
                        );

                        return (
                          <TableCell
                            key={c.id}
                            sx={
                              isFixedLeft
                                ? {
                                    position: "sticky",
                                    left: 0,
                                    borderRight: "1px solid divider",
                                    backgroundColor: "background.paper",
                                    boxShadow:
                                      "2px 0 10px -3px rgba(0, 0, 0, 0.1)",
                                  }
                                : {}
                            }
                          >
                            {flexRender(
                              c.column.columnDef.cell,
                              c.getContext()
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
      <TablePagination
        count={props.data.length}
        page={page}
        onPageChange={(_, value) => setPage(value)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(e) => setRowsPerPage(Number(e.target.value))}
        labelRowsPerPage=""
        rowsPerPageOptions={[5, 10, 15]}
        sx={{ border: "none" }}
      />
    </Box>
  );
}

MuiDataTable.defaultProps = {
  data: [],
  columnFixedLeft: [],
};
