import { Routes, Route, Navigate } from "react-router-dom";
import Box from "@mui/material/Box";

import { useAuth } from "../context/Auth";
import * as Pages from "../pages";
import { Guard } from "./Guard";

export function RoutesApp() {
  let { user } = useAuth();

  return (
    <Box sx={{ height: "100%", maxWidth: 870, margin: "auto" }}>
      <Routes>
        <Route path="/draft/signup" element={<Pages.PSignup />}/>

        <Route path="/auth" element={<Guard when={!!user} goto="/app" />}>
          <Route index element={<Pages.PLogin />} />
        </Route>

        <Route path="/app" element={<Guard when={!user} goto="/" />}>
          <Route index element={<Pages.PStart />} />
          <Route path="inventory/gas" element={<Pages.PInventoryGas />} />
          <Route path="inventory/products" element={<Pages.PInventoryProducts />} />
          <Route path="sales/gas" element={<Pages.PSalesGas />} />
          <Route path="sales/products" element={<Pages.PSalesProducts />} />
          <Route path="sys/password" element={<Pages.PSysPassword />} />
        </Route>

        <Route path="/" element={<Navigate to="/auth" />} />
      </Routes>
    </Box>
  );
}
