import { Navigate, Outlet } from "react-router-dom";

type PropsGuard = {
  when: boolean;
  goto: string;
};

export function Guard(props: PropsGuard) {
  let { when, goto } = props;

  if (when) {
    return <Navigate to={goto} />;
  }

  return <Outlet />;
}
