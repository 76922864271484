import React from "react";
import { useDebounce } from "use-debounce";

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function useFilter(term: string, data: any[], accessor: string) {
  let [debouncedFilter] = useDebounce(term, 500);

  return React.useMemo(() => {
    if (!debouncedFilter.trim()) return data;

    return data.filter((item) => {
      let value = item[accessor];

      if (typeof value === "string") {
        return value.toLowerCase().includes(debouncedFilter.toLowerCase());
      }

      return false;
    });
  }, [debouncedFilter, data, accessor]);
}

export function toCurrency(value: number) {
  return value.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
    maximumFractionDigits: 2,
  });
}

export function getTimezoneOffset() {
  let tz = new Date().getTimezoneOffset() / 60;
  let tz_sign = tz < 0 ? "+" : "-";

  return Number(`${tz_sign}${tz}`);
}
