import "react-dates/initialize";
import "moment/dist/locale/pt-br";
import 'react-toastify/dist/ReactToastify.css';

import ReactDOM from "react-dom/client";
import moment from "moment";

import { App } from "./App";

let root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

moment.locale("pt-br");

root.render(App());
