import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { MuiDataTable, FLayout, FHeader, FButtonBack, RAChart } from "../components";
import { useInventoryGas } from "../App.api";

let tableColumns = [
  { accessorKey: "name", header: "Nome" },
  { accessorKey: "nameTank", header: "Tanque" },
  { accessorKey: "current", header: "Estoque" },
  { accessorKey: "capacity", header: "Capacidade" },
];

export function PInventoryGas() {
  let { data = [] } = useInventoryGas();

  let chartSeries = React.useMemo(
    () => [
      {
        name: "Em estoque",
        data: data?.map((i) => ({
          x: i.name,
          y: i.current,
          goals: [
            {
              name: "Capacidade",
              value: i.capacity,
              strokeWidth: 2,
              strokeDashArray: 2,
              strokeColor: "blue",
            },
          ],
        })),
      },
    ],
    [data]
  );

  return (
    <FLayout.Base>
      <FHeader
        ContentLeft={
          <>
            <FButtonBack />
            <Typography variant="h6">Estoque de Combustiveis</Typography>
          </>
        }
      />
      <Box
        sx={{
          display: "flex",
          height: "100%",
          alignItems: "flex-end",
          flexDirection: "column",
          p: 1,
        }}
      >
        <RAChart
          type="bar"
          options={{
            plotOptions: { bar: { horizontal: true } },
            dataLabels: { enabled: true, offsetX: 5 },
            legend: {
              show: true,
              showForSingleSeries: true,
              customLegendItems: ["Em estoque", "Capacidade"],
              markers: { fillColors: ["#00E396", "blue"] },
            },
          }}
          series={chartSeries}
          hasData={data.length > 0}
        />
        <MuiDataTable data={data} columns={tableColumns} columnFixedLeft={["name"]} />
      </Box>
    </FLayout.Base>
  );
}
