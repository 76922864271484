import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";

import axios from "./config/axios";
import { useAppStore } from "./App.store";
import { getTimezoneOffset } from "./App.utils";

export function useInventoryGas() {
  let { currentOrg } = useAppStore();

  return useQuery(
    ["inventoryGas", currentOrg?.id],
    async () => {
      let { data } = await axios.get<
        Array<{
          id: string;
          descricaoCombustivel: string;
          descricaoTanque: string;
          estoqueAtual: number;
          estoqueCapacidade: number;
        }>
      >(`/estoque/combustiveis/${currentOrg?.id}`);

      return data.map((i) => {
        let name = i.descricaoCombustivel.trim();

        return {
          id: i.id,
          name: name.length > 20 ? name.substring(0, 20) + "..." : name,
          nameTank: i.descricaoTanque,
          current: i.estoqueAtual,
          capacity: i.estoqueCapacidade,
        };
      });
    },
    { enabled: !!currentOrg?.id }
  );
}

export function useInventoryProducts() {
  let { currentOrg } = useAppStore();

  return useQuery(
    ["inventoryProducts", currentOrg?.id],
    async () => {
      let { data } = await axios.get<
        Array<{
          id: string;
          descricaoProduto: string;
          estoqueAtual: number;
          estoqueCapacidade: number;
        }>
      >(`/estoque/produtos/${currentOrg?.id}?min=1`);

      return data.map((i: any) => {
        let name = i.descricaoProduto.trim();

        return {
          id: i.id,
          name: name.length > 20 ? name.substring(0, 20) + "..." : name,
          current: i.estoqueAtual,
          min: i.estoqueMinimo,
        };
      });
    },
    { enabled: !!currentOrg?.id }
  );
}

export function useSalesProducts(dateStart: string, dateEnd?: string) {
  let { currentOrg } = useAppStore();
  let dateEndResolved = dateEnd ?? dateStart;

  return useQuery(
    ["salesProducts", currentOrg?.id, dateStart, dateEndResolved],
    async () => {
      let { data } = await axios.get<
        Array<{
          idGrupoProduto: string;
          descricaoGrupoProduto: string;
          quantidadeVendida: number;
          valorVendido: number;
        }>
      >(`/venda/produtos/${currentOrg?.id}/${dateStart}/${dateEndResolved}`);

      return data.map((i) => {
        let name = i.descricaoGrupoProduto.trim();

        return {
          idGrupoProduto: i.idGrupoProduto,
          descricaoGrupoProduto: name.length > 20 ? name.substring(0, 20) + "..." : name,
          quantidadeVendida: i.quantidadeVendida,
          valorVendido: i.valorVendido,
        };
      });
    },
    { enabled: !!currentOrg?.id }
  );
}

export function useSalesGas(dateStart: string, dateEnd?: string) {
  let { currentOrg } = useAppStore();
  let dateEndResolved = dateEnd ?? dateStart;

  return useQuery(
    ["salesGas", currentOrg?.id, dateStart, dateEndResolved],
    async () => {
      let { data } = await axios.get<
        Array<{
          idCombustivel: string;
          descricaoCombustivel: string;
          quantidadeVendida: number;
          valorVendido: number;
        }>
      >(`/venda/combustiveis/${currentOrg?.id}/${dateStart}/${dateEndResolved}`);

      return data.map((i) => {
        let name = i.descricaoCombustivel.trim();

        return {
          idCombustivel: i.idCombustivel,
          descricaoCombustivel: name.length > 20 ? name.substring(0, 20) + "..." : name,
          quantidadeVendida: i.quantidadeVendida,
          valorVendido: i.valorVendido,
        };
      });
    },
    { enabled: !!currentOrg?.id }
  );
}

export function useSysPassword() {
  return useQuery(["sysPassword"], async () => {
    let { data } = await axios.get(
      `/sys/password/${moment().add(getTimezoneOffset(), "hours").toISOString()}`
    );

    return data;
  });
}

export function useCreateSignupDraft(opts = {} as any) {
  return useMutation((input: { name: string, email: string, password: string, companies: string[] }) => {
    return axios.post("/requisicoes", {
      type: 0,
      json: JSON.stringify(input)
    });
  }, opts)
}