let PREFIX = "@fluid-v1/";

function get(key: string) {
  try {
    let payload = window.localStorage.getItem(PREFIX + key);

    if (payload) {
      payload = JSON.parse(payload);
    }

    return payload;
  } catch {
    return null;
  }
}

function set(key: string, value: any) {
  try {
    let paylaod = JSON.stringify(value);

    window.localStorage.setItem(PREFIX + key, paylaod);
  } catch {}
}

function rm(key: string) {
  window.localStorage.removeItem(PREFIX + key);
}

let localStorageWrapper = { get, set, rm, PREFIX };

export default localStorageWrapper;
