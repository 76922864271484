import React from "react";
import * as RQ from "@tanstack/react-query";

import axios from "../config/axios";
import localStorage from "../config/local-storage";
import { sleep } from "../App.utils";
import { useAppStore } from "../App.store";
import { MuiBackdrop } from "../components";

const DELAY_FEEDBACK_IN_MS = 500;

export type Credentials = { email: string; password: string };

export type User = {
  id: string;
  ativo: boolean;
  email: string;
  pessoa: { nome: string; nomeFantasia: string; tipoPapel: number };
  empresas: Array<{
    id: string;
    tipoModulo: number;
    pessoa: { nome: string; nomeFantasia: string; cnpjCpf: string };
  }>;
};
interface AuthContextValue {
  user?: User;
  login: RQ.UseMutateAsyncFunction<User, any, Credentials>;
  logout: RQ.UseMutateAsyncFunction<any, any, void, any>;
  refetchUser: (options?: RQ.RefetchOptions) => Promise<RQ.QueryObserverResult<User, Error>>;
  error: Error | null;
}

let authContext = React.createContext<AuthContextValue | null>(null);

type PropsProviderAuth = { children: React.ReactNode };

export function ProviderAuth(props: PropsProviderAuth) {
  let appStore = useAppStore();
  let queryClient = RQ.useQueryClient();

  let {
    data: user,
    error,
    isLoading,
    isStale,
    isSuccess,
    refetch,
  } = RQ.useQuery<User, Error>({
    queryKey: ["session"],
    queryFn: async () => {
      if (localStorage.get("jwt")) {
        return (await axios.get("/sessao")).data;
      }

      return null;
    },
  });

  let mutLogin = RQ.useMutation({
    mutationFn: async (payload: Credentials) => {
      await sleep(DELAY_FEEDBACK_IN_MS);

      return (await axios.post("/sessao", payload)).data;
    },
    onSuccess: ({ token, user }) => {
      localStorage.set("jwt", token);

      appStore.setCurrentOrg({
        id: user.empresas[0].id,
        cnpjCpf: user.empresas[0].pessoa.cnpjCpf,
        nomeFantasia: user.empresas[0].pessoa.nomeFantasia,
        tipoModulo: user.empresas[0].tipoModulo,
      });

      queryClient.setQueryData(["session"], user);
    },
  });

  let mutLogout = RQ.useMutation({
    mutationFn: async () => {
      await sleep(DELAY_FEEDBACK_IN_MS);
      localStorage.rm("jwt");
    },
    onSuccess: () => {
      queryClient.clear();
      appStore.setCurrentOrg(null);
    },
  });

  let value = React.useMemo(
    () => ({
      user,
      error,
      refetchUser: refetch,
      login: mutLogin.mutateAsync,
      logout: mutLogout.mutateAsync,
    }),
    [user, error, refetch, mutLogin.mutateAsync, mutLogout.mutateAsync]
  );

  if (isSuccess) {
    let isLoading = mutLogin.isLoading || mutLogout.isLoading;

    return (
      <authContext.Provider value={value}>
        {props.children}
        {isLoading ? <MuiBackdrop /> : null}
      </authContext.Provider>
    );
  }

  if (isLoading || isStale) {
    return <MuiBackdrop />;
  }

  return <span>Ops ... {error?.message}</span>;
}

export function useAuth() {
  return React.useContext(authContext) as AuthContextValue;
}
