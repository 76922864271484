import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/system/styleFunctionSx";

type PropsFTotal = {
  label: string;
  value: string;
  sx?: SxProps;
};

export function FTotal(props: PropsFTotal) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        flex: 1,
        p: 3,
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid",
        borderColor: "divider",
        // backgroundColor: "action.hover",
        ...props.sx,
      }}
    >
      <Typography sx={{ fontSize: 16, fontWeight: "lighter" }}>
        {props.label}
      </Typography>
      <Typography sx={{ fontSize: 16 }}>{props.value}</Typography>
    </Box>
  );
}
