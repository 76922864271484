import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import moment, { Moment } from "moment";

import {
  FLayout,
  FHeader,
  FButtonBack,
  FTotal,
  MuiDataTable,
  RDDateRangePicker,
  RAChart,
} from "../components";
import { useSalesGas } from "../App.api";
import { toCurrency } from "../App.utils";

let tableColumns = [
  { accessorKey: "descricaoCombustivel", header: "Nome" },
  {
    accessorKey: "valorVendido",
    header: "Valor",
    cell: (v: any) => toCurrency(v.getValue()),
  },
  {
    accessorKey: "quantidadeVendida",
    header: "Quantidade",
    cell: (v: any) => v.getValue().toFixed(4),
  },
];

export function PSalesGas() {
  let [startDate, setStartDate] = React.useState<Moment>(moment());
  let [endDate, setEndDate] = React.useState<Moment>(moment());
  let { data = [] } = useSalesGas(
    startDate.startOf("day").toISOString(),
    endDate.endOf("day").toISOString()
  );

  let top5 = React.useMemo(
    () =>
      data
        .slice()
        .sort((a, b) => b.valorVendido - a.valorVendido)
        .slice(0, 5),
    [data]
  );

  let [chartSeries, chartLabels] = React.useMemo(
    () => [
      top5.map((i) => i.valorVendido),
      top5.map((i) => i.descricaoCombustivel),
    ],
    [top5]
  );

  let totalSales = React.useMemo(
    () =>
      data
        .reduce((acc, cur) => acc + cur.valorVendido, 0)
        .toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    [data]
  );

  let totalQuantity = React.useMemo(
    () => data.reduce((acc, cur) => acc + cur.quantidadeVendida, 0).toString(),
    [data]
  );

  return (
    <FLayout.Base>
      <FHeader
        ContentLeft={
          <>
            <FButtonBack />
            <Typography variant="h6">Venda de Combustíveis</Typography>
          </>
        }
      />
      <Box
        sx={{
          display: "flex",
          minHeight: "100%",
          flexDirection: "column",
          alignItems: "center",
          p: 0.5,
        }}
      >
        <RDDateRangePicker
          sx={{ marginTop: -15 }}
          startDate={startDate}
          endDate={endDate}
          onDatesChange={({ startDate, endDate }: any) => {
            if (startDate) {
              setStartDate(startDate);
            }

            if (endDate) {
              setEndDate(endDate);
            }
          }}
        />
        <RAChart
          type="polarArea"
          options={{
            labels: chartLabels,
            fill: {
              opacity: 0.8,
            },
            title: {
              text: "Top 5 Combustíveis",
            },
          }}
          series={chartSeries}
          hasData={top5.length > 0}
        />
        <MuiDataTable
          data={data}
          columns={tableColumns}
          columnFixedLeft={["descricaoCombustivel"]}
        />
        <Box
          sx={{ mt: "auto", pb: 2, display: "flex", flexWrap: "wrap", gap: 2 }}
        >
          <FTotal label="Total valor" value={totalSales} />
          <FTotal label="Total quantidade" value={totalQuantity} />
        </Box>
      </Box>
    </FLayout.Base>
  );
}
