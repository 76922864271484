import { createElement, useMemo } from "react";
import { Link, LinkProps } from "react-router-dom";
import Box from "@mui/material/Box";

type PropsFLink = LinkProps & {
  target?: string;
  sx?: any;
};

export function FLink(props: PropsFLink) {
  let to = props.to as string;
  
  let el = useMemo(() => {
    if (to.startsWith("http")) {
      return (
        <a target={props.target ?? "_blank"} href={to} style={props.sx}>
          {props.children}
        </a>
      );
    }
  
    return createElement(Link, props);
  }, [to, props]);


  return (
    <Box sx={{
      ...props.sx,
      "& a": {
        color: 'text.primary',
      }
    }}>
      {el}
    </Box>
  )
}
