import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import Typography from "@mui/material/Typography";
import MIWarning from "@mui/icons-material/Warning";

import { FLink } from "./FLink";

type PropsFButtonBox = {
  to: string;
  Icon: typeof MIWarning;
  label: string;
  description?: string;
};

export function FButtonBox(props: PropsFButtonBox) {
  let { backgroundColor, color } = props.to.startsWith("http")
    ? { backgroundColor: "action.hover", color: "text.primary" }
    : { backgroundColor: "primary.main", color: "white" };

  return (
    <Card
      sx={{
        width: 105,
        height: 105,
        "& a": {
          textDecoration: "none",
        },
      }}
    >
      <FLink to={props.to} sx={{ height: '100%' }}>
        <CardActionArea
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor,
            color,
          }}
        >
          <props.Icon sx={{ fontSize: 40, mb: 0.5, mt: 1 }} />
          <Typography
            sx={{ mb: -0.5, fontSize: "0.85rem" }}
            variant="subtitle2"
          >
            {props.label}
          </Typography>
          <Typography
            variant="caption"
            sx={{ fontSize: "0.7rem", height: "0.7rem" }}
          >
            {props.description}
          </Typography>
        </CardActionArea>
      </FLink>
    </Card>
  );
}

FButtonBox.defaultProps = {
  to: "/app",
  Icon: MIWarning,
  label: "Sample",
};
