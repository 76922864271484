import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import moment, { Moment } from "moment";

import {
  FLayout,
  FHeader,
  FButtonBack,
  FTotal,
  MuiDataTable,
  RDDateRangePicker,
  RAChart,
} from "../components";
import { useSalesProducts } from "../App.api";
import { toCurrency } from "../App.utils";

let tableColumns = [
  { accessorKey: "descricaoGrupoProduto", header: "Nome" },
  {
    accessorKey: "valorVendido",
    header: "Valor",
    cell: (v: any) => toCurrency(v.getValue()),
  },
  {
    accessorKey: "quantidadeVendida",
    header: "Quantidade",
    cell: (v: any) => v.getValue().toFixed(4),
  },
];

export function PSalesProducts() {
  let [startDate, setStartDate] = React.useState<Moment>(moment());
  let [endDate, setEndDate] = React.useState<Moment>(moment());
  let { data = [] } = useSalesProducts(
    startDate.startOf("day").toISOString(),
    endDate.endOf("day").toISOString()
  );

  let top5 = React.useMemo(
    () =>
      data
        .slice()
        .sort((a, b) => b.valorVendido - a.valorVendido)
        .slice(0, 5),
    [data]
  );

  let [chartSeries, chartLabels] = React.useMemo(
    () => [
      top5.map((i) => i.valorVendido),
      top5.map((i) => i.descricaoGrupoProduto),
    ],
    [top5]
  );

  let total = React.useMemo(
    () =>
      data
        .reduce((acc, cur) => acc + cur.valorVendido, 0)
        .toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    [data]
  );

  return (
    <FLayout.Base>
      <FHeader
        ContentLeft={
          <>
            <FButtonBack />
            <Typography variant="h6">Venda de Produtos</Typography>
          </>
        }
      />
      <Box
        sx={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          alignItems: "center",
          p: 0.5,
        }}
      >
        <RDDateRangePicker
          sx={{ marginTop: -15 }}
          startDate={startDate}
          endDate={endDate}
          onDatesChange={({ startDate, endDate }: any) => {
            if (startDate) {
              setStartDate(startDate);
            }
            if (endDate) {
              setEndDate(endDate);
            }
          }}
        />
        <RAChart
          type="polarArea"
          options={{
            labels: chartLabels,
            fill: {
              opacity: 0.8,
            },
            title: {
              text: "Top 5 Produtos",
            },
          }}
          series={chartSeries}
          hasData={top5.length > 0}
        />
        <MuiDataTable
          data={data}
          columns={tableColumns}
          columnFixedLeft={["descricaoGrupoProduto"]}
        />
        <Box sx={{ mt: "auto", pb: 2 }}>
          <FTotal label="Total de vendas" value={total} />
        </Box>
      </Box>
    </FLayout.Base>
  );
}
