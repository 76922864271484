import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconSearch from "@mui/icons-material/Search";
import { SxProps } from "@mui/system/styleFunctionSx";

type PropsMuiTextFieldSearch = {
  value: string;
  onChangeText: (value: string) => void;
  label: string;
  sx?: SxProps;
};

export function MuiTextFieldSearch(props: PropsMuiTextFieldSearch) {
  return (
    <TextField
      fullWidth
      variant="standard"
      label={props.label}
      sx={props.sx}
      value={props.value}
      onChange={(e) => props.onChangeText(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconSearch />
          </InputAdornment>
        ),
      }}
    />
  );
}

MuiTextFieldSearch.defaultProps = {
  sx: {},
};
