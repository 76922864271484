import { useFormik } from "formik";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { MuiTextFieldSecure, FLink, FLayout } from "../components";
import { useAuth } from "../context/Auth";
import yup from "../config/yup";
import { useAppStore } from "../App.store";

let validationSchema = yup.object({
  email: yup.string().label("E-mail").email().required(),
  password: yup.string().label("Senha").required(),
});

export function PLogin() {
  let { login } = useAuth();
  let { logo } = useAppStore();
  let { values, touched, errors, handleSubmit, handleChange, setErrors } = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema,
    onSubmit: (values) => {
      login(values, {
        onError: () => {
          setErrors({
            email: "E-mail ou senha incorretos",
            password: "E-mail ou senha incorretos",
          });
        },
      });
    },
  });

  return (
    <FLayout.Base sx={{ alignContent: 'center', justifyContent: 'center' }}>
      <Box
        sx={{
          width: "100%",
          maxWidth: 550,
          display: "flex",
          flexDirection: "column",
          p: 2,
          gap: 2,
          alignItems: "center",
          mt: 'auto',
          mx: 'auto'
        }}
        component="form"
        onSubmit={handleSubmit}
      >
        <Box sx={{ display: 'flex', width: '100%', mb: 1 }}>
          <Box sx={{ width: 35, mr: 1 }} component="img" src={logo} />
          <Typography variant="h4" sx={{ fontWeight: 'light' }}>Fluid</Typography>
        </Box>

        <TextField
          value={values.email}
          onChange={handleChange}
          name="email"
          label="E-mail"
          size="medium"
          error={touched.email && Boolean(errors.email)}
          helperText={touched.email && errors.email}
          fullWidth
        />
        <MuiTextFieldSecure
          value={values.password}
          onChange={handleChange}
          name="password"
          label="Senha"
          size="medium"
          error={touched.password && Boolean(errors.password)}
          helperText={touched.password && errors.password}
          fullWidth
        />

        <Button type="submit" variant="contained" size="large" disableElevation fullWidth>
          Entrar
        </Button>
      </Box>
      <FLink to="/draft/signup" sx={{ m: 'auto', mb: 2 }}>
        Solicite seu acesso aqui
      </FLink>
    </FLayout.Base>
  );
}
