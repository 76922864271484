import { motion } from "framer-motion";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Gravatar from "react-gravatar";

import { useAuth } from "../context/Auth";

type PropsFGreatings = {
  nameUser: string;
  nameOrg: string;
};

export function FGreatings(props: PropsFGreatings) {
  let { user } = useAuth();

  return (
    <Box component="section" sx={{ display: "flex", alignItems: "center", p: 1, pt: 0 }}>
      <Gravatar size={45} email={user?.email} style={{ borderRadius: "50%" }} />
      <Box sx={{ display: "flex", flexDirection: "column", p: 1 }}>
        <Typography sx={{ fontSize: "1.85rem", mb: "-0.55rem" }}>
          Olá <b>{props.nameUser?.split(" ")[0]}</b>{" "}
          <motion.span
            style={{ display: "inline-block" }}
            animate={{ rotate: [0, 25, 0, 25, 0] }}
            transition={{ repeat: Infinity, repeatDelay: 6, delay: 1 }}
          >
            👋
          </motion.span>
        </Typography>
        <Typography sx={{ fontSize: "0.9rem" }}>
          Acessando como <b style={{ textTransform: "capitalize" }}>{props.nameOrg}</b>
        </Typography>
      </Box>
    </Box>
  );
}

FGreatings.defaultProps = {
  nameUser: "Sample",
  nameOrg: "Sample",
};
