import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { FLayout, MuiDataTable, MuiTextFieldSearch, FHeader, FButtonBack } from "../components";
import { useInventoryProducts } from "../App.api";
import { useFilter } from "../App.utils";

let tableColumns = [
  { accessorKey: "name", header: "Nome" },
  { accessorKey: "current", header: "Estoque" },
  { accessorKey: "min", header: "Mínimo" },
];

export function PInventoryProducts() {
  let { data = [] } = useInventoryProducts();

  let [term, setTerm] = React.useState("");
  let dataFiltered = useFilter(term, data, "name");

  return (
    <FLayout.Base>
      <FHeader
        ContentLeft={
          <>
            <FButtonBack />
            <Typography variant="h6">Estoque de Produtos</Typography>
          </>
        }
      />
      <Box
        sx={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          p: 1,
        }}
      >
        <MuiTextFieldSearch
          label="Filtrar produtos"
          value={term}
          onChangeText={setTerm}
          sx={{ mb: 2 }}
        />
        <MuiDataTable data={dataFiltered} columns={tableColumns} columnFixedLeft={["name"]} />
      </Box>
    </FLayout.Base>
  );
}
