import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

type PropsMuiBackdrop = {
  blur?: boolean;
};

export function MuiBackdrop(props: PropsMuiBackdrop) {
  let { blur } = props;

  return (
    <Backdrop
      open
      sx={{
        color: "primary.main",
        backgroundColor: "rgb(245, 245, 245)",
        backdropFilter: blur ? "blur(4px)" : "none",
        zIndex: 1600,
      }}
    >
      <CircularProgress color="inherit" size="3.5rem" />
    </Backdrop>
  );
}
