import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";

type Props = {
  onChange: (value: any[]) => void;
  options: any[];
  freeSolo?: boolean;
  fullWidth?: boolean;
  label?: string;
  placeholder?: string;
  name?: string;
  error?: boolean;
  helperText?: boolean | string | string[];
  yupError?: any
}

export function MuiAutoComplete(props: Props) {
  let { options = [], onChange, fullWidth = true, freeSolo = true, label, placeholder, name, error, helperText, yupError} = props

  console.log(yupError)

  return (
    <Autocomplete
      fullWidth={fullWidth}
      multiple
      freeSolo={freeSolo}
      clearOnBlur
      onChange={(_e, value) => {
        onChange(value)
      }}
      options={options}
      renderTags={(value: number[], getTagProps) =>
        value.map((option: number, index: number) => (
          <Chip variant="outlined" color={error ? 'error' : 'primary'} label={String(option)} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          name={name}
          error={error}
          helperText={helperText}
        />
      )}
    />
  )
}