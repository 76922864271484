import React from "react";
import Box from "@mui/material/Box";

type PropsFHeader = {
  ContentLeft?: React.ReactNode;
  ContentRight?: React.ReactNode;
};

export function FHeader(props: PropsFHeader) {
  return (
    <Box
      component="header"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 1,
        position: "sticky",
        top: 0,
        left: 0,
        zIndex: 20,
        backgroundColor: "background.paper",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          marginRight: "auto",
          alignItems: "center",
        }}
      >
        {props.ContentLeft}
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          marginLeft: "auto",
          alignItems: "center",
        }}
      >
        {props.ContentRight}
      </Box>
    </Box>
  );
}
