import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

import { useAppStore } from "../App.store";
import { useCallback, useEffect, useRef, useState } from "react";

type PropsRAChart = {
  options?: ApexOptions;
  series?: any[];
  type: "bar" | "polarArea";
  hasData: boolean;
};

export function RAChart(props: PropsRAChart) {
  let { mode } = useAppStore();
  let { palette } = useTheme();

  let refContainer = useRef<HTMLElement>(null);
  let [width, setWidth] = useState(() => {
    if (refContainer.current) {
      return refContainer.current.clientWidth;
    }

    return 300;
  });

  let onresize = useCallback(() => {
    if (refContainer.current) {
      setWidth(refContainer.current.clientWidth);
      console.log(refContainer.current.clientWidth);
    }
  }, [refContainer]);

  useEffect(() => {
    onresize();
    window.addEventListener("resize", onresize);

    return () => {
      window.removeEventListener("resize", onresize);
    };
  }, [onresize]);

  console.log({ width });

  return (
    <Box
      ref={refContainer}
      sx={{
        width: "100%",
        maxWidth: 350,
        mb: 1,
        p: 1,
        borderColor: "divider",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: width,
      }}
    >
      {props.hasData ? (
        <Chart
          type={props.type}
          width={width}
          height={width}
          options={{
            ...props.options,
            chart: {
              background: palette.background.paper,
              toolbar: { show: true, tools: { download: true } },
              ...(props.options?.chart ?? {}),
            },
            legend: {
              position: "bottom",
            },
            theme: { mode },
          }}
          series={props.series}
        />
      ) : (
        <Box sx={{ width: "100%", height: 220 }}></Box>
      )}
    </Box>
  );
}

RAChart.defaultProps = {
  hasData: true,
};
