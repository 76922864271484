import Box from "@mui/material/Box";

import { useAppStore } from "../App.store";

export function FLogo() {
  let { logo, mode } = useAppStore();

  return (
    <Box
      component="img"
      sx={{
        height: 36,
        filter: mode === "dark" ? "brightness(0) invert(1)" : "none",
      }}
      src={logo}
      alt="Logo"
    />
  );
}
