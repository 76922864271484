import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { FLayout, FHeader, FButtonBack } from "../components";
import { useSysPassword } from "../App.api";

export function PSysPassword() {
  let { data } = useSysPassword();

  return (
    <FLayout.Base>
      <FHeader
        ContentLeft={
          <>
            <FButtonBack />
            <Typography variant="h6">Senha técnica</Typography>
          </>
        }
      />
      <Box
        sx={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h2" sx={{ fontWeight: "bold" }}>
          {data?.sysPassword}
        </Typography>
      </Box>
    </FLayout.Base>
  );
}
