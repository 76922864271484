import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import yup from "../config/yup";

import { MuiAutoComplete } from '../components'

import {
  FLayout,
  FHeader,
  FButtonBack,
  MuiTextFieldSecure,
} from "../components";
import { useCreateSignupDraft } from "../App.api";
import { useNavigate } from "react-router-dom";

let validationSchema = yup.object({
  name: yup.string().label("Nome").required(),
  email: yup.string().label("E-mail").email().required(),
  cpf: yup.string().label("CPF").required(),
  password: yup.string().label("Senha").required(),
  companies: yup.array().of(yup.string().test('len', 'CNPJ deve conter exatamente 14 digitos, ', val => String(val).length === 14)).label('CNPJ Empresas'),
});

export function PSignup() {
  let navigate = useNavigate();
  let createDraft = useCreateSignupDraft({ onSuccess: () => {
    navigate('/auth');
    toast.success('Cadastro solicitado com sucesso!');
  } });

  let { values, touched, errors, handleSubmit, handleChange, setFieldValue, validateField } = useFormik({
    initialValues: { name: "", cpf: "", email: "", password: "", companies: [] as string[] },
    validationSchema,
    onSubmit: (values) => {
      createDraft.mutate(values);
    },
  });

  return (
    <FLayout.Base>
      <FHeader
        ContentLeft={
          <>
            <FButtonBack />
            <Typography variant="h6">Novo acesso</Typography>
          </>
        }
      />
      <Box
        sx={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          alignItems: "center",
          p: 2,
          gap: 2,
        }}
        component="form"
        onSubmit={handleSubmit}
      >
        <TextField
          value={values.name}
          onChange={handleChange}
          name="name"
          label="Nome Completo"
          size="medium"
          error={touched.name && Boolean(errors.name)}
          helperText={touched.name && errors.name}
          fullWidth
        />
        <TextField
          value={values.cpf}
          onChange={handleChange}
          name="cpf"
          label="CPF"
          size="medium"
          error={touched.cpf && Boolean(errors.cpf)}
          helperText={touched.cpf && errors.cpf}
          fullWidth
        />
        <MuiAutoComplete
          fullWidth
          onChange={(value) => {
            setFieldValue("companies", value)
            validateField("companies")
          }}
          options={values.companies}
          label="CNPJ Empresas"
          name="companies"
          error={touched.companies && Boolean(errors.companies)}
          helperText={(touched.companies && errors.companies) ?? '* Apenas números'}
          yupError={errors.companies}
        />
        <TextField
          value={values.email}
          onChange={handleChange}
          name="email"
          label="E-mail"
          size="medium"
          error={touched.email && Boolean(errors.email)}
          helperText={touched.email && errors.email}
          fullWidth
        />
        <MuiTextFieldSecure
          value={values.password}
          onChange={handleChange}
          name="password"
          label="Senha"
          size="medium"
          error={touched.password && Boolean(errors.password)}
          helperText={touched.password && errors.password}
          fullWidth
        />
        <Button type="submit" variant="contained" size="large" disableElevation fullWidth sx={{ mt: 'auto' }}>Solicitar acesso</Button>
      </Box>
    </FLayout.Base>
  );
}
