import axios from "axios";
import localStorage from "./local-storage";

let instance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

instance.interceptors.request.use(
  async (config) => {
    if (localStorage.get("jwt")) {
      // @ts-ignore
      config.headers["Authorization"] = `Bearer ${localStorage.get("jwt")}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default instance;
